import axios from 'axios'
import { getJwtToken } from '@ccs/il.authentication.avatar'

import { IUsagePayload } from '../types'

const getHeaders = async () => {
  const token = await getJwtToken()
  
  return {
    headers: { Authorization: `Bearer ${token}` }
  }
}

const registerUsage = async (userId:string, eckId: string, entitlementId:string) => {
  const payload: IUsagePayload = {
    userId : userId,
    eckId : eckId,
    entitlementId : entitlementId
  }
  const headers = await getHeaders()

  axios.post('api/test/usage', payload, headers)
}

export { 
  registerUsage
}
