import axios from 'axios'
import { Entitlement, Product } from '../types'
import { getJwtToken } from '@ccs/il.authentication.avatar'

const getHeaders = async () => {
  const token = await getJwtToken()
  
  return {
    headers: { Authorization: `Bearer ${token}` }
  }
}

const getEntitlementProducts = async (): Promise<Product[]> => {
  const headers = await getHeaders()

  return axios.get(`api/test/entitlement/products`, headers).then((response) => {
    const products = response.data as Product[]

    return products
  })
}

const getEntitlements = async (): Promise<Entitlement[]> => {
  const headers = await getHeaders()

  return axios.get(`api/test/entitlement`, headers).then((response) => {
    const entitlements = response.data as Entitlement[]

    return entitlements
  })
}

export { getEntitlementProducts, getEntitlements }
