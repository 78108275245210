import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form'

import { IEventDropdownOption, IUpdateFormFields } from '..'
import { getGroups, getGroup, getEntitlements, registerUsage } from '../../api'
import { Group, Product, Entitlement } from '../../api/types';
import './LearningApplicationForm.css';

const LearningApplicationForm = () => {
  const { register, formState, errors, setValue } = useForm<
    IUpdateFormFields
  >({
    mode: 'all',
  })

  const [selectedGroup, setSelectedGroup] = useState<Group>()
  const [entitlements, setEntitlements] = useState<Entitlement[]>([])
  const [eventDropdownOptions, setEventDropdownOptions] = useState<IEventDropdownOption[]>([])

  useEffect(() => {
    register({ name: 'enabledEvents', type: 'custom' }, { required: true })

    getGroups().then(result => setEventDropdownOptions(result)).then(r =>
      getEntitlements().then(
        result => setEntitlements(result)
      )
    )
  }, [register])

  const selectGroup = (selectedGroupId: string) => {
    getGroup(selectedGroupId).then(result => setSelectedGroup(result));
  }



  return (
    <>
      <h2>Learning Application:</h2>
        <label htmlFor="availableGroupsDropdown">Select group:</label>

        <select
          id="availableGroupsDropdown"
          onChange={(event) => selectGroup(event.target.value)}
        >
          <option value="">Select Group</option>
          {eventDropdownOptions.map(option => 
            <option key={option.id} value={option.id}>{option.name}</option>
          )}
        </select>

        <table>
          <tr>
            <th>Role</th>
            <th>Firstname</th>
            <th>Surname</th>
            {entitlements?.map(entitlement => 
              <th title={entitlement.product.name}>{entitlement.product.ean}</th>
            )}
          </tr>
          <tr>
            <td rowSpan={(selectedGroup?.teachers ? selectedGroup.teachers.length+1 : 0 )}>Teacher(s)</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {selectedGroup?.teachers.map(teacher => 
            <tr>
              <td>{teacher.firstname}</td>
              <td>{teacher.surname}</td>
              {entitlements?.map(entitlement => 
                <td><button type="button" onClick={() => postUsage(entitlement.id, teacher.id, teacher.eckid)}>Use</button></td>
              )}
            </tr>
          )}
          <tr>
            <td rowSpan={(selectedGroup?.students ? selectedGroup.students.length+1 : 0 )}>Student(s)</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          {selectedGroup?.students.map(student => 
            <tr>
              <td>{student.firstname}</td>
              <td>{student.surname}</td>
              {entitlements?.map(entitlement => 
                <td><button type="button" onClick={() => postUsage(entitlement.id, student.id, student.eckid)}>Use</button></td>
              )}
            </tr>
          )}
        </table>


        <ul>
          {entitlements?.map(entitlement => 
            <li>{entitlement.product.ean} - {entitlement.product.name}</li>
          )}
        </ul>

    </>
  );
}

export { LearningApplicationForm }


function postUsage(entitlementId: string, userid: string, eckId: string) {
  registerUsage(userid, eckId, entitlementId);
}

